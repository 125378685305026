.index {
  font-size: 18px;
  color: #61dafb;

  .black {
    color: seagreen;

  }

  .affix {
    background-color: #786DE4;
    width: 100%;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }


  .guide {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 10%;
    transition: all 0.3s;

    .titleImg {
      display: block;
      width: 154px;
      height: 56px;
    }

    .topMenu {
      .menu {
        background-color: rgba(1, 1, 1, 0);
        border-bottom: none;
        font-size: 18px;
        font-weight: bolder;
        color: #e6e6e6;
      }
    }
  }

  .firstPage {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;

    .canvas {
      position: absolute;
      width: 100%;
      height: 100vh;
      z-index: 1;
    }

    .content {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10%;

      .left {
        span {
          display: block;
          font-size: 28px;
          color: white;
          font-weight: bolder;
          text-align: left;
        }

        i {
          text-align: left;
          display: block;
          font-size: 16px;
          color: white;
        }

        img {
          display: block;
          width: 200px;
        }

        div {
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;

          button {
            span {
              font-size: 16px;
            }
          }
        }
      }

      .right {
        img {
          display: block;
          width: 500px;
        }
      }
    }
  }

  .secondPage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;

    .left {
      text-align: left;
      color: #282c34;
      font-size: 16px;

      .title {
        font-size: 28px;
        font-weight: bolder;
      }

      div {
        img {
          display: inline-block;
          width: 40px;
        }
      }

    }

    .right {
      img {
        display: block;
        width: 500px;
      }
    }
  }

  .thirdPage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    background-color: #FBB03C;

    .left {
      img {
        display: block;
        width: 500px;
      }
    }

    .right {
      width: 500px;
      font-size: 16px;
      color: white;
      text-align: left;

      .title {
        font-size: 28px;
        padding-bottom: 10px;
      }
    }

  }

  .fourthPage {
    background-color: #786DE4;
    width: 100%;
    height: 100vh;
    color: white;
    padding-top: 100px;

    .img {
      width: 100%;
      padding-bottom: 50px;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-weight: bolder;
      padding-bottom: 20px;
    }

    .btnGroup {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      width: 400px;
    }
  }

  .fifthPage {
    background-color: white;
    width: 100%;
    height: 100vh;
    color: black;
    padding-top: 100px;

    .title {
      font-size: 36px;
      font-weight: bolder;
      text-align: center;
    }

    .title-2 {
      padding-top: 20px;
      text-align: center;
      font-size: 16px;
    }

    .content {
      padding: 30px 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        div {
          width: 250px;
          text-align: center;
        }

        img {
          display: block;
          width: 250px;
        }
      }

      .right {
        width: 400px;
        padding-top: 70px;
      }
    }

  }


  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: white;
    border-bottom: 2px solid white;
  }
}
